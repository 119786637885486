import { graphql } from "gatsby";
// import _get from "lodash.get";

import DetailPageList from "app/components/DetailPageList";

export const PAGE_QUERY = graphql`
  query SitePageQuery($id: String) {
    entry: craftSitePageFormPageEntry(id: { eq: $id }) {
      id
      title
      displayTitle
      formgallery {
        id
        updatedAt: dateUpdated
        uri
        title
        ... on Craft_galleries_galleries_Entry {
          galleryPreviewImages {
            id
            updatedAt: dateUpdated
            ...AssetCardImage
          }
        }
      }
      exploration {
        ... on Craft_exploration_BlockType {
          id
          updatedAt: dateUpdated
          sectionTitle
          pages {
            id
            updatedAt: dateUpdated
            ...SiteDetailPage
          }
        }
      }
    }
  }
`;

export default DetailPageList;
